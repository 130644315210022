import { IUserRights, ADMIN_LEVEL, FEATURES, IStripeConfigPart } from '../../types';

export let dzrOrgUnits = [
  { id: 32, name: 'DZRN', lang: 'DZR Deutsches Zahnärztliches Rechenzentrum GmbH, Neuss (32)' },
  { id: 64, name: 'DZRS', lang: 'DZR Deutsches Zahnärztliches Rechenzentrum GmbH, Stuttgart (64)' },
  { id: 128, name: 'DZRF', lang: 'DZR Deutsches Zahnärztliches Rechenzentrum GmbH, FRH (128)' },
  { id: 256, name: 'DZRH', lang: 'DZR Deutsches Zahnärztliches Rechenzentrum GmbH, Hamburg (256)' },
];

export let dzrAbzOrgUnits = [{ id: 8, name: 'ABZ', lang: 'ABZ Zahnärztliches Rechenzentrum für Bayern GmbH (8)' }];

function dzrOrgUnitResolved() {
  let ret: any = {};
  for (const ou of dzrOrgUnits) {
    ret[ou.id] = ou.name;
  }
  return ret;
}

export let dzrOrgUnitResolve = dzrOrgUnitResolved();

export const PDF_RENDERING_SCALE_FACTOR = 3;
export const EPS_RENDERING_SCALE_FACTOR = 200; // dpi

export const defaultUserRights: IUserRights = {
  email: '',
  cid: '',
  adminLevel: ADMIN_LEVEL.CLIENT_ADMIN,
  views: [],
  extids: [],
  isExternApiOnly: false,
  notificationSettings: {
    emails: { main: { active: false } },
    todos: {
      active: false,
      days: {
        mo: false,
        di: false,
        mi: false,
        do: false,
        fr: false,
        sa: false,
        so: false,
      },
      sortDate: 'asc',
      updateStyle: 'full',
    },
    hkp: {
      tageVorFristablauf: {
        active: false,
      },
      beiFristablauf: false,
      nurEigeneFristen: false,
    },
  },
  viewSpecificBehandlerAccess: {},
  ipfiltering: {
    active: false,
  },
};

export const r4cFeatureMap: any = {
  [FEATURES.FACTORING]: 'f',
  [FEATURES.FACTORINGEWE]: 'F',
  [FEATURES.DOCUMENTS_SMALL]: 'd',
  [FEATURES.DOCUMENTS_BIG]: 'D',
  [FEATURES.DOCUMENTS_FLAT]: 'D*',
  [FEATURES.ANAMNESE_WHITE]: 'a',
  [FEATURES.ANAMNESE_RED]: 'A',
  [FEATURES.ANAMNESE_BLACK]: 'A*',
  [FEATURES.ANAMNESE_DIAMOND]: 'A**',
};

const oldMetricsPrices = [
  'plan_Fs2CT2BNmeSmzi',
  'plan_Fs2StaTKULETw2',
  'plan_GI9GUDW8KNkQB3',
  'plan_GI9GYvDiZPrA7m',
  'price_1NIWk9KCumfvsWbQE79Ppr38',
];

const extraFactoringSchnittstellePrice = 'price_1JP7n2KCumfvsWbQwDUhP2WL';
const dataWarehouse25Price = 'price_1LhsqmKCumfvsWbQ0VCwXURS';
const r2csvPrice = 'price_1M8298KCumfvsWbQ9W7RWI8e';

export function createFeatureToPriceMap(stripeCfg: IStripeConfigPart) {
  const ROSE_FEATURE_TO_STRIPE_PLAN: { [feat in FEATURES]?: string[] } = {
    [FEATURES.METRICS]: [stripeCfg.rose_metrics, stripeCfg.charly_analytics, ...oldMetricsPrices],
    [FEATURES.MULTICLIENT]: [stripeCfg.rose_metrics_multiclientlogin],

    [FEATURES.FACTORING]: [stripeCfg.rose_plan_factoring],
    [FEATURES.FACTORINGEWE]: [stripeCfg.rose_plan_factoring_ewe],

    [FEATURES.DOCUMENTS_SMALL]: [stripeCfg.rose_plan_document_signer_small_volume],
    [FEATURES.DOCUMENTS_BIG]: [
      stripeCfg.rose_plan_document_signer_big_base,
      stripeCfg.rose_plan_document_signer_big_volume,
    ],
    [FEATURES.DOCUMENTS_FLAT]: [stripeCfg.rose_plan_document_signer_flat],

    [FEATURES.ANAMNESE_WHITE]: [stripeCfg.rose_plan_anamnese_white_volume, 'price_1MddYuKCumfvsWbQJtetKIA4'],
    [FEATURES.ANAMNESE_RED]: [stripeCfg.rose_plan_anamnese_red_base, stripeCfg.rose_plan_anamnese_red_volume],
    [FEATURES.ANAMNESE_BLACK]: [stripeCfg.rose_plan_anamnese_black],
    [FEATURES.ANAMNESE_DIAMOND]: [stripeCfg.rose_plan_anamnese_diamond],

    [FEATURES.EXTRAFACTORINGSCHNITTSTELLE]: [extraFactoringSchnittstellePrice],
    [FEATURES.DATAWAREHOUSE25]: [dataWarehouse25Price],
    [FEATURES.R2CSV]: [r2csvPrice],
  };
  return ROSE_FEATURE_TO_STRIPE_PLAN;
}

export const kzvLUT = {
  '1': 'KZV Stuttgart',
  '2': 'KZV Baden-Württemberg',
  '3': 'KZV Regierungsbezirk Tübingen',
  '4': 'KZV Niedersachsen',
  '5': 'KZV Regierungsbezirk Karlsruhe',
  '6': 'KZV Rheinland-Pfalz',
  '7': 'KZV Regierungsbezirk Freiburg',
  '01': 'KZV Stuttgart',
  '02': 'KZV Baden-Württemberg',
  '03': 'KZV Regierungsbezirk Tübingen',
  '04': 'KZV Niedersachsen',
  '05': 'KZV Regierungsbezirk Karlsruhe',
  '06': 'KZV Rheinland-Pfalz',
  '07': 'KZV Regierungsbezirk Freiburg',
  '11': 'KZV Bayern',
  '13': 'KZV Nordrhein',
  '20': 'KZV Hessen',
  '30': 'KZV Berlin',
  '31': 'KZV im Lande Bremen',
  '32': 'KZV Hamburg',
  '35': 'KZV Saarland',
  '36': 'KZV Schleswig-Holstein',
  '37': 'KZV Westfalen-Lippe',
  '52': 'KZV Mecklenburg-Vorpommern',
  '53': 'KZV Land Brandenburg',
  '54': 'KZV Sachsen-Anhalt',
  '55': 'KZV Thüringen',
  '56': 'KZV Sachsen',
};
